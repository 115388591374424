import { isDesignServicesTemplateOrder, isDesignServicesOrder } from './orderTypes'

// any updates to this must be carried over to order.rb as well
export const STATES = {
  NEW: 'new',
  NEEDS_PREFLIGHT: 'preflight',
  RESTAGED: 'restaged',
  NEEDS_PROOF: 'proof',
  CUSTOMER_REVIEW: 'customer_review',
  INTERNAL_REVIEW: 'internal_review',
  READY_FOR_SEQUENCING: 'sequencing',
  IN_SEQUENCING: 'in_sequencing',
  READY_FOR_NEST: 'nest',
  NESTED: 'nested',
  READY_TO_CUT: 'cut',
  READY_FOR_TAPPING: 'tapping',
  READY_FOR_COUNTERSINKING: 'countersinking',
  READY_FOR_POST: 'post',
  READY_FOR_TUMBLING: 'tumbling',
  READY_FOR_ETCH: 'etch',
  READY_FOR_DIMPLE_FORMING: 'dimple_forming',
  READY_FOR_BEND: 'bend',
  READY_FOR_HARDWARE: 'hardware',
  READY_FOR_POWDER: 'ready_for_powder', // powder here meaning actual internal powder coating
  READY_FOR_FINISHING: 'powder', // powder here meaning any external finishing.
  IN_FINISHING: 'in_powder', // powder here meaning any external finishing.
  READY_FOR_QC: 'qc',
  IN_FINAL_QC: 'in_final_qc',
  WAITING_FOR_TEMPLATE: 'waiting_for_template',
  TEMPLATE_RECEIVED: 'template_received',
  REVERSAL: 'reversal',
  WAITING_ON_MATERIAL: 'material',
  READY_TO_SHIP: 'ship',
  SHIPPED: 'shipped',
  PICKUP: 'pickup',
  ARCHIVED: 'archived',
  PAYMENT: 'payment',
  CREATED: 'created',
  EXPIRED: 'expired',
  CHECKOUT_FAILED: 'failed'
}

// Common state transitions
export const NEXT_STATES = {
  'new': [STATES.NEEDS_PREFLIGHT, STATES.NEEDS_PROOF, STATES.CUSTOMER_REVIEW, STATES.REVERSAL, STATES.READY_TO_SHIP, STATES.READY_FOR_NEST, STATES.WAITING_FOR_TEMPLATE],
  'preflight': [STATES.CUSTOMER_REVIEW, STATES.RESTAGED, STATES.READY_FOR_NEST],
  'proof': [STATES.CUSTOMER_REVIEW, STATES.READY_FOR_NEST],
  'restaged': [STATES.CUSTOMER_REVIEW, STATES.NEEDS_PROOF, STATES.READY_FOR_NEST],
  'sequencing': [STATES.IN_SEQUENCING],
  'in_sequencing': [STATES.NEEDS_PREFLIGHT, STATES.READY_FOR_NEST],
  'customer_review': [STATES.READY_FOR_NEST, STATES.RESTAGED, STATES.REVERSAL],
  'internal_review': [STATES.READY_FOR_NEST, STATES.RESTAGED],
  'nest': [STATES.INTERNAL_REVIEW, STATES.NESTED],
  'nested': [STATES.READY_TO_CUT],
  'cut': [STATES.READY_FOR_TAPPING, STATES.READY_FOR_COUNTERSINKING, STATES.READY_FOR_POST, STATES.READY_FOR_TUMBLING, STATES.READY_FOR_BEND, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING, STATES.READY_FOR_DIMPLE_FORMING, STATES.READY_FOR_HARDWARE, STATES.READY_FOR_QC],
  'tapping': [STATES.INTERNAL_REVIEW, STATES.READY_FOR_COUNTERSINKING, STATES.READY_FOR_POST, STATES.READY_FOR_TUMBLING],
  'countersinking': [STATES.INTERNAL_REVIEW, STATES.READY_FOR_POST, STATES.READY_FOR_TUMBLING],
  'post': [STATES.READY_FOR_TUMBLING, STATES.READY_TO_SHIP, STATES.READY_FOR_BEND, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING, STATES.READY_FOR_DIMPLE_FORMING, STATES.READY_FOR_HARDWARE],
  'tumbling': [STATES.READY_TO_SHIP, STATES.READY_FOR_BEND, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING, STATES.READY_FOR_DIMPLE_FORMING, STATES.READY_FOR_HARDWARE],
  'etch': [STATES.INTERNAL_REVIEW, STATES.READY_FOR_QC, STATES.READY_FOR_BEND],
  'dimple_forming': [STATES.READY_FOR_BEND, STATES.READY_FOR_HARDWARE, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING],
  'bend': [STATES.INTERNAL_REVIEW, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING, STATES.READY_FOR_HARDWARE, STATES.READY_FOR_QC],
  'ready_for_powder': [STATES.READY_FOR_QC, STATES.READY_FOR_FINISHING],
  'powder': [STATES.IN_FINISHING],   // Ready for Finishing
  'in_powder': [STATES.READY_FOR_QC], // In Finishing
  'hardware': [STATES.READY_FOR_BEND, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING],
  'qc': [STATES.INTERNAL_REVIEW, STATES.IN_FINAL_QC],
  'in_final_qc': [STATES.INTERNAL_REVIEW, STATES.READY_TO_SHIP],
  'waiting_for_template': [STATES.TEMPLATE_RECEIVED],
  'template_received': [STATES.REVERSAL],
  'reversal': [STATES.CUSTOMER_REVIEW, STATES.SHIPPED],
  'material': [STATES.READY_TO_CUT],
  'pickup': [STATES.ARCHIVED],
  'archived': [],
  'shipped': [STATES.ARCHIVED],
  'payment': [STATES.NEW],
  'expired': [STATES.PAYMENT]
}

// State transitions that are 'forward' - as opposed to transitions which pause, or reverse the order flow
export const GO_STATES = {
  'new': [STATES.READY_TO_SHIP, STATES.READY_FOR_SEQUENCING, STATES.READY_FOR_NEST, STATES.WAITING_FOR_TEMPLATE, STATES.REVERSAL],
  'preflight': [STATES.READY_FOR_NEST],
  'proof': [STATES.READY_FOR_NEST],
  'restaged': [STATES.READY_FOR_NEST, STATES.NEEDS_PROOF],
  'sequencing': [STATES.IN_SEQUENCING],
  'in_sequencing': [STATES.READY_FOR_NEST],
  'customer_review': [STATES.READY_FOR_NEST, STATES.NESTED, STATES.REVERSAL, STATES.READY_TO_CUT, STATES.READY_FOR_ETCH, STATES.READY_FOR_BEND, STATES.READY_FOR_TAPPING,
    STATES.READY_FOR_POST, STATES.READY_FOR_TUMBLING, STATES.READY_FOR_QC, STATES.READY_TO_SHIP],
  'internal_review': [STATES.READY_FOR_NEST, STATES.NESTED, STATES.READY_TO_CUT, STATES.READY_FOR_ETCH, STATES.READY_FOR_BEND, STATES.READY_FOR_TAPPING,
    STATES.READY_FOR_POST, STATES.READY_FOR_TUMBLING, STATES.READY_FOR_QC, STATES.READY_TO_SHIP],
  'nest': [STATES.NESTED],
  'nested': [STATES.READY_TO_CUT],
  'cut': [STATES.READY_FOR_TAPPING, STATES.READY_FOR_COUNTERSINKING, STATES.READY_FOR_POST, STATES.READY_FOR_TUMBLING, STATES.READY_FOR_DIMPLE_FORMING, STATES.READY_FOR_HARDWARE, STATES.READY_FOR_BEND, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING, STATES.READY_FOR_QC],
  'etch': [STATES.READY_FOR_QC, STATES.READY_FOR_BEND],
  'dimple_forming': [STATES.READY_FOR_HARDWARE, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING, STATES.READY_FOR_BEND, STATES.READY_FOR_QC],
  'bend': [STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING, STATES.READY_FOR_HARDWARE, STATES.READY_FOR_QC],
  'tapping': [STATES.READY_FOR_COUNTERSINKING, STATES.READY_FOR_BEND, STATES.READY_FOR_POST, STATES.READY_FOR_TUMBLING, STATES.READY_FOR_QC],
  'countersinking': [STATES.READY_FOR_BEND, STATES.READY_FOR_DIMPLE_FORMING, STATES.READY_FOR_HARDWARE, STATES.READY_FOR_POST, STATES.READY_FOR_TUMBLING],
  'post': [STATES.READY_FOR_TUMBLING, STATES.READY_FOR_ETCH, STATES.READY_FOR_BEND, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING, STATES.READY_FOR_DIMPLE_FORMING, STATES.READY_FOR_HARDWARE, STATES.READY_FOR_QC],
  'tumbling': [STATES.READY_FOR_ETCH, STATES.READY_FOR_BEND, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING, STATES.READY_FOR_DIMPLE_FORMING, STATES.READY_FOR_HARDWARE, STATES.READY_FOR_QC],
  'powder': [STATES.IN_FINISHING],
  'in_powder': [STATES.READY_FOR_DIMPLE_FORMING, STATES.READY_FOR_HARDWARE, STATES.READY_FOR_QC],
  'hardware': [STATES.READY_FOR_QC, STATES.READY_FOR_POWDER, STATES.READY_FOR_FINISHING],
  'qc': [STATES.IN_FINAL_QC],
  'in_final_qc': [STATES.READY_TO_SHIP],
  'waiting_on_template': [STATES.TEMPLATE_RECEIVED],
  'template_received': [STATES.REVERSAL],
  'reversal': [STATES.SHIPPED],
  'material': [STATES.READY_TO_CUT],
  'ship': [STATES.SHIPPED, STATES.PICKUP],
  'shipped': [STATES.ARCHIVED],
  'pickup': [STATES.ARCHIVED],
  'archived': [],
  'payment': [STATES.NEW],
  'expired': [STATES.PAYMENT]
}

/**
 * @returns {Array<string>} States the user can select
 */
function availableStates(current) {
  return NEXT_STATES[current]
}

// Array of all available states
export const ALL_STATES = Object.values(STATES)

// Non-Production States - these are states typically before and after production
export const NON_PRODUCTION_STATES = [
  STATES.NEW,
  STATES.NEEDS_PREFLIGHT,
  STATES.RESTAGED,
  STATES.NEEDS_PROOF,
  STATES.CUSTOMER_REVIEW,
  STATES.INTERNAL_REVIEW,
  STATES.READY_FOR_SEQUENCING,
  STATES.IN_SEQUENCING,
  STATES.WAITING_FOR_TEMPLATE,
  STATES.TEMPLATE_RECEIVED,
  STATES.READY_TO_SHIP,
  STATES.SHIPPED,
  STATES.PICKUP,
  STATES.ARCHIVED,
  STATES.PAYMENT
]

// Array of all states considered in 'review' state
// any updates to this must be carried over to order.rb as well
export const REVIEW_STATES = [
  STATES.NEEDS_PREFLIGHT,
  STATES.RESTAGED,
  STATES.CUSTOMER_REVIEW,
  STATES.INTERNAL_REVIEW,
  STATES.NEEDS_PROOF
]

// Array of all states considered in 'prepping' state
// any updates to this must be carried over to order.rb as well
export const PREPPING_STATES = [
  STATES.READY_FOR_NEST,
  STATES.NESTED,
  STATES.WAITING_FOR_TEMPLATE
]

// Array of all states considered in 'processing' state
// any updates to this must be carried over to order.rb as well
export const PROCESSING_STATES = [
  STATES.IN_FINISHING,
  STATES.WAITING_ON_MATERIAL,
  STATES.READY_FOR_BEND,
  STATES.READY_TO_CUT,
  STATES.READY_FOR_COUNTERSINKING,
  STATES.READY_FOR_DIMPLE_FORMING,
  STATES.READY_FOR_HARDWARE,
  STATES.READY_FOR_ETCH,
  STATES.READY_FOR_POWDER,
  STATES.READY_FOR_FINISHING,
  STATES.READY_FOR_POST,
  STATES.READY_FOR_TUMBLING,
  STATES.READY_FOR_TAPPING,
  STATES.REVERSAL,
  STATES.TEMPLATE_RECEIVED,
]

// Array of all states considered in 'final' state
// any updates to this must be carried over to order.rb as well
export const FINAL_STATES = [
  STATES.READY_TO_SHIP,
  STATES.READY_FOR_QC,
  STATES.IN_FINAL_QC
]

// Array of all states considered in 'completed' state
// any updates to this must be carried over to order.rb as well
export const COMPLETED_STATES = [
  STATES.SHIPPED,
  STATES.PICKUP,
  STATES.ARCHIVED,
]

// Array of all states considered to be 'post final qc'.
export const POST_QC_STATES = [
  STATES.READY_TO_SHIP,
  STATES.SHIPPED,
  STATES.PICKUP,
  STATES.ARCHIVED,
]

// Recuts can be initiated from any of these states
export const RECUT_ELIGIBLE_STATES = [
  STATES.READY_TO_CUT,
  STATES.READY_FOR_TAPPING,
  STATES.READY_FOR_COUNTERSINKING,
  STATES.READY_FOR_POST,
  STATES.READY_FOR_ETCH,
  STATES.READY_FOR_BEND,
  STATES.READY_FOR_TUMBLING,
  STATES.READY_FOR_POWDER,
  STATES.READY_FOR_FINISHING,
  STATES.IN_FINISHING,
  STATES.READY_FOR_DIMPLE_FORMING,
  STATES.READY_FOR_HARDWARE,
  STATES.READY_FOR_QC,
  STATES.IN_FINAL_QC,
  STATES.WAITING_ON_MATERIAL
]

/**
 * Gets a UI/forward friendly display of a state
 * @param {string} state
 */
export const getStateDisplay = function (state) {
  switch (state) {
    case 'all':
      return 'All'
    case STATES.CREATED:
      return 'Created'
    case STATES.NEW:
      return 'New'
    case STATES.EXPIRED:
      return "Expired"
    case STATES.NEEDS_PREFLIGHT:
      return "Needs Preflight"
    case STATES.NEEDS_PROOF:
      return "Staging"
    case STATES.RESTAGED:
      return "Restaged"
    case STATES.READY_FOR_SEQUENCING:
      return "Ready for Sequencing"
    case STATES.IN_SEQUENCING:
      return "In Sequencing"
    case STATES.READY_FOR_NEST:
      return "Ready for Nest"
    case STATES.NESTED:
      return "Nested"
    case STATES.READY_FOR_ETCH:
      return "Ready for Etch"
    case STATES.READY_FOR_DIMPLE_FORMING:
        return "Ready for Dimple Forming"
    case STATES.READY_FOR_BEND:
      return "Ready for Bending"
    case STATES.READY_FOR_TAPPING:
      return "Ready for Tapping"
    case STATES.READY_FOR_COUNTERSINKING:
      return "Ready for Countersink"
    case STATES.READY_FOR_POWDER:
      return "Ready for Powder"
    case STATES.READY_FOR_FINISHING:
      return "Ready for Finishing"
    case STATES.IN_FINISHING:
      return "In Finishing"
    case STATES.READY_FOR_HARDWARE:
      return "Ready for Hardware"
    case STATES.READY_FOR_POST:
      return "Ready for Deburring"
    case STATES.READY_FOR_TUMBLING:
      return "Ready for Tumbling"
    case STATES.CUSTOMER_REVIEW:
      return "Customer Review"
    case STATES.INTERNAL_REVIEW:
      return "Internal Review"
    case STATES.READY_TO_CUT:
      return "Ready to Cut"
    case STATES.READY_FOR_QC:
      return "Ready for Final QC"
    case STATES.IN_FINAL_QC:
      return "In Final QC"
    case STATES.READY_TO_SHIP:
      return "Ready for Shipping"
    case STATES.WAITING_FOR_TEMPLATE:
      return "Waiting for Template"
    case STATES.TEMPLATE_RECEIVED:
      return "Template Received"
    case STATES.REVERSAL:
      return "Reversal"
    case STATES.WAITING_ON_MATERIAL:
      return "Waiting on Material"
    case STATES.SHIPPED:
      return "Shipped"
    case STATES.PICKUP:
      return "Ready for Pickup"
    case STATES.ARCHIVED:
      return "Archived"
    case STATES.PAYMENT:
      return "Needs Payment"
    case STATES.CHECKOUT_FAILED:
      return "Checkout Failed"
    default:
      return state
  }
}

/**
 * Returns ordered list of available states to select from
 * @param {string} currentState The current state of the order
 */
export const getAvailableStates = function (current) {
  return availableStates(current)
}

export const stateDisplay = function (state, order = null, delivered = false) {
  switch (state) {
    case STATES.NEW:
      return 'We have received your order and it is being processed'
    case STATES.CUSTOMER_REVIEW: {
      const contacted = order && order.state_actions.includes('contacted') && order.state === STATES.CUSTOMER_REVIEW
      return contacted ? 'We are awaiting your response/approval. Please check your email - we have a question about your order' : 'Your order is being reviewed'
    }
    case STATES.NEEDS_PREFLIGHT:
    case STATES.READY_FOR_SEQUENCING:
    case STATES.IN_SEQUENCING:
      return "Your order is being reviewed and prepped for production"
    case STATES.READY_FOR_NEST:
      return "Your parts are being produced"
    case STATES.READY_FOR_ETCH:
      return "Your parts are being etched"
    case STATES.READY_FOR_DIMPLE_FORMING:
      return "Your parts are having dimples formed"
    case STATES.READY_FOR_BEND:
      return "Your parts are being bent"
    case STATES.READY_FOR_TAPPING:
      return "Your parts are being tapped"
    case STATES.READY_FOR_COUNTERSINKING:
      return "Your parts are being countersunk"
    case STATES.READY_FOR_POWDER:
    case STATES.READY_FOR_FINISHING:
      return "Your parts are being prepped for finishing"
    case STATES.IN_FINISHING:
      return "Your parts are being finished (7-10 days)"
    case STATES.READY_FOR_HARDWARE:
      return "Your parts are having hardware installed"
    case STATES.READY_FOR_POST:
      return "Your parts are being deburred"
    case STATES.READY_FOR_TUMBLING:
      return "Your parts are being tumbled"
    case STATES.READY_FOR_QC:
      return "Your parts are undergoing quality inspection"
    case STATES.WAITING_FOR_TEMPLATE:
      return "Waiting for your template"
    case STATES.TEMPLATE_RECEIVED:
      return "Your template have been received"
    case STATES.REVERSAL: {
      let result = ''
      if (order) {
        result = isDesignServicesTemplateOrder(order) ? "Your template is being converted" : "Your drawing is being converted"
      }
      return result
    }
    case STATES.READY_TO_SHIP:
      return order && order.local ? "Your parts are being prepped for pickup" : "Your parts are being prepped for shipping"
    case STATES.PICKUP:
      return "Your parts are ready for pickup!"
    case STATES.SHIPPED: {
      let result = "Your parts are on their way"
      if (order) {
        if (isDesignServicesOrder(order)) {
          result = "Your design is complete and ready to accept"
        }
        else if (order.local) {
          result = "Your parts are ready for pickup!"
        }
        else if (delivered) {
          result = "Your parts have been delivered"
        }
      }
      return result
    }
    // any state not defined will be ignored
    default:
      return undefined
  }
}

export function stateDisplayCustomer(order) {
  switch (order.state) {
    case STATES.NEW:
      return 'New'
    case STATES.NEEDS_PREFLIGHT:
    case STATES.READY_FOR_SEQUENCING:
    case STATES.IN_SEQUENCING:
    case STATES.CUSTOMER_REVIEW:
    case STATES.INTERNAL_REVIEW:
    case STATES.NEEDS_PROOF:
      return 'Review'
    case STATES.READY_FOR_NEST:
    case STATES.NESTED:
    case STATES.WAITING_FOR_TEMPLATE:
      return 'Prepping'
    case STATES.READY_TO_CUT:
      return 'Cutting'
    case STATES.READY_FOR_POST:
      return "Deburring"
    case STATES.READY_FOR_TUMBLING:
      return "Tumbling"
    case STATES.READY_FOR_ETCH:
      return 'Etching'
    case STATES.READY_FOR_TAPPING:
      return 'Tapping'
    case STATES.READY_FOR_COUNTERSINKING:
      return 'Countersinking'
    case STATES.READY_FOR_DIMPLE_FORMING:
      return 'Dimple Forming'
    case STATES.READY_FOR_HARDWARE:
      return 'Hardware'
    case STATES.READY_FOR_POWDER:
    case STATES.READY_FOR_FINISHING:
    case STATES.IN_FINISHING:
      return 'Finishing'
    case STATES.READY_FOR_BEND:
      return 'Bending'
    case STATES.READY_TO_SHIP:
    case STATES.READY_FOR_QC:
    case STATES.IN_FINAL_QC:
      return 'Final Inspection'
    case STATES.TEMPLATE_RECEIVED:
    case STATES.REVERSAL:
      return 'Processing'
    case STATES.SHIPPED:
    case STATES.ARCHIVED:
      return order.canceled ? 'Canceled' : 'Completed'
    case STATES.PICKUP:
      return "Ready for Pickup"
    case STATES.PAYMENT:
      return "Pending"
    case STATES.EXPIRED:
      return "Expired"
    default:
      return ""
  }
}

/**
 * Returns a dollar amount in currency format
 * @param {string|integer} amount The dollar amount to be formatted
 */
export const formatCurrency = function (amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return formatter.format(amount)
}
