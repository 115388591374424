import scs from 'shared/scs'
import findIndex from 'lodash/findIndex'
import Decimal from 'decimal.js'

// initial state
const cartState = {
  address_billing: {
    'name': '',
    'company': '',
    'address1': '',
    'address2': '',
    'city': '',
    'province': '',
    'zip': ''
  },
  address_shipping: {
    'name': '',
    'email': '',
    'phone': '',

    'company': '',
    'address1': '',
    'address2': '',
    'city': '',
    'province': '',
    'zip': ''
  },
  batch_select: [],
  blind_invoice: false,
  tax_exempt: false,
  items: [],
  liftgate_required: undefined,
  is_formal_quote: false,
  quote_items: [],
  quote_totals: {},
  quote_cart: {},
  po_number: {
    hasPo: false,
    poNumber: ''
  },
  production_method: 'standard',
  note: '',
  attachments: [],
  discount_code: '',
  discount_applied: '',
  totals: {},
  completed_order: {},
  delivery_options: [],
  rates: [],
  ship_time: {},
  ship_message: {},
  affirm_checkout_token: '',
  affirm_transaction_id: '',
  shipping_method: 'standard',
  current_stage: '',
}

// getters
const getters = {
  affirm_checkout_token: state => state.affirm_checkout_token,
  affirm_transaction_id: state => state.affirm_transaction_id,
  attachments: (state) => state.attachments,
  batch_select: (state) => state.batch_select,
  blind_invoice: (state) => state.blind_invoice,
  completed_order: (state) => state.completed_order,
  current_stage: (state) => state.current_stage,
  discount_code: (state) => state.discount_code,
  discount_applied: (state) => state.discount_applied,
  est_ship_date: (state) => state.totals?.est_ship_date,
  is_formal_quote: (state) => state.is_formal_quote,
  is_selected: (state) => (id) => state.batch_select.includes(id),
  items: (state) => state.items,
  note: (state) => state.note,
  paid_in_full: (state) => state.paid_in_full,
  po_number: (state) => state.po_number,
  production_method: (state) => state.production_method,
  quote_cart: (state) => state.quote_cart,
  quote_items: (state) => state.quote_items,
  quote_totals: (state) => state.quote_totals,
  delivery_options: (state) => state.delivery_options,
  rates: (state) => state.rates,
  shipping_method: (state) => state.shipping_method,
  tax_exempt: (state) => state.tax_exempt,
  totals: (state) => state.totals,
  total_weight: (state) => {
    try {
      let total = new Decimal(0.0)
      state.items.forEach((item) => { total = total.add(item.total_weight || 0) })
      return total
    }
    catch (e) {
      return 0
    }
  },
}

// actions
const actions = {
  accept(_, data) {
    return scs.acceptSavedCart(data)
  },
  create({ commit }, data) {
    return scs.createCartItem(data)
      .then((response) => {
        commit('addCartItem', response.data)
        commit('setTotals', response.meta.totals)
      })
  },
  clone({ commit }, data) {
    return scs.cloneCartItem(data)
      .then((response) => {
        commit('addCartItem', response.data)
        commit('setTotals', response.meta.totals)
        return response
      })
  },
  createBatch({ commit }, data) {
    return scs.createBatchCartItems(data)
      .then((response) => {
        commit('addBatchCartItems', response.data)
        commit('setTotals', response.meta.totals)
        return response
      })
  },
  create_from_order(_, data) {
    return scs.createCartFromOrder(data)
  },
  delete({ commit }, data) {
    return scs.deleteCartItem(data)
      .then((response) => {
        commit('removeCartItem', data)
        commit('setTotals', response.meta.totals)
      })
  },
  deleteMultiple({ commit }, data) {
    return scs.deleteMultipleCartItems(data)
      .then((response) => {
        commit('removeCartItems', data)
        commit('setTotals', response.meta.totals)
      })
  },
  fetch({ commit }, data = {}) {
    return scs.getCartItems(data)
      .then((response) => {
        commit('setCartItems', response.data)
        commit('setTotals', response.meta.totals)
      })
  },
  preview({ commit }, data) {
    return scs.previewSavedCart(data)
      .then((response) => {
        commit('setQuoteItems', response.data)
        commit('setQuoteTotals', response.meta.totals)
        commit('setQuoteCart', response.meta.saved_cart)
      })
  },
  refreshDeliveryOptions({ commit }, data) {
    return scs.getDeliveryOptions(data)
      .then((response) => {
        commit('setDeliveryOptions', response.data)
      })
  },
  refreshRates({ commit }, data) {
    return scs.getShippingRates(data)
      .then((response) => {
        commit('setRates', response.data)
      })
  },
  refresh({ commit, state }, data) {
    // If tax exempt is checked, don't include sales tax
    if (state.tax_exempt) data.include_tax = false

    return scs.getCartItems(data)
      .then((response) => {
        if (response.data) {
          response.data.forEach(item => {
            commit('setCartItem', item)
          })
          commit('setTotals', response.meta.totals)
        }
      })
  },

  update({ commit }, data) {
    return scs.updateCartItem(data)
      .then((response) => {
        commit('setCartItem', response.data)
        commit('setTotals', response.meta.totals)
      })
  },
}

// mutations
const mutations = {
  addCartItem(state, item) {
    const newItems = state.items.slice()
    const index = findIndex(newItems, (d) => d.id === item.id)
    if (index !== -1) {
      newItems[index] = item
      state.items = newItems
    }
    else {
      state.items.push(item)
    }
  },
  addBatchCartItems(state, items) {
    state.items = state.items.concat(items)
  },
  addAttachment(state, url) {
    state.attachments.push({ filename_url: url.split('?')[0] })
  },
  setAffirmCheckoutToken(state, token) {
    state.affirm_checkout_token = token
  },
  setAffirmTransactionId(state, token) {
    state.affirm_transaction_id = token
  },
  addRemoveBatchSelect(state, item) {
    if (item.checked && !state.batch_select.includes(item.id)) {
      state.batch_select.push(item.id)
    }
    else {
      state.batch_select = state.batch_select.filter(id => id !== item.id)
    }
  },
  setAddressBilling(state, address) {
    state.address_billing = address
  },
  setAddressShipping(state, address) {
    state.address_shipping = address
  },
  setBatchSelect(state, batch) {
    state.batch_select = batch
  },
  setBlindInvoice(state, value) {
    state.blind_invoice = value || false
  },
  setCartItem(state, data) {
    const newItems = state.items.slice()
    const index = findIndex(newItems, (d) => d.id === data.id)
    if (index !== -1) {
      newItems[index] = data
      state.items = newItems
    }
  },
  setCartItems(state, items) {
    state.items = items
    state.attachments = []
  },
  setCompletedOrder(state, completedOrder) {
    state.completed_order = completedOrder
  },
  setCurrentStage(state, newStage) {
    state.current_stage = newStage
  },
  setDeliveryOptions(state, options) {
    state.delivery_options = options
  },
  setDiscountApplied(state, discountCode) {
    state.discount_applied = discountCode
  },
  setDiscountCode(state, discount) {
    state.discount_code = discount
  },
  setHasPo(state, hasPo) {
    state.po_number.hasPo = hasPo
  },
  setIsFormalQuote(state, isFormalQuote) {
    state.is_formal_quote = isFormalQuote
  },
  setNote(state, note) {
    state.note = note
  },
  setLiftgateRequired(state, required) {
    state.liftgate_required = required
  },
  setPoNumber(state, po) {
    state.po_number.poNumber = po
  },
  setProductionMethod(state, method) {
    state.production_method = method
  },
  setQuoteCart(state, cart) {
    state.quote_cart = cart
  },
  setQuoteItems(state, items) {
    state.quote_items = items
  },
  setQuoteTotals(state, totals) {
    state.quote_totals = totals
  },
  setRates(state, rates) {
    state.rates = rates
  },
  setShipMessage(state, data) {
    state.ship_message = data
  },
  setShippingMethod(state, method) {
    state.shipping_method = method
  },
  setTaxExempt(state, taxExempt) {
    state.tax_exempt = taxExempt || false
  },
  setTotals(state, totals) {
    state.totals = totals
  },
  removeAttachment(state, index) {
    state.attachments.splice(index, 1)
  },
  removeCartItem(state, item) {
    const newItems = state.items.slice()
    const index = findIndex(newItems, (d) => d.id === item.id)
    if (index !== -1) {
      newItems.splice(index, 1)
      state.items = newItems

      if (state.items.length === 0) {
        state.attachments = []
      }
    }
  },
  removeCartItems(state, data) {
    state.items = state.items.filter((d) => !data.ids.includes(d.id))
    if (state.items.length === 0) {
      state.attachments = []
    }
  },
}

export default {
  namespaced: true,
  state: cartState,
  getters,
  actions,
  mutations
}
